import logo from "../../images/vcogs2.png";
import "./Home.css";
import { useState } from "react";
import { postCall } from "../../http-service";
import { BackendAPIs, getBaseApi } from "../../constant";

function Home() {
  let [name, setName] = useState("");

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <b className="text-lg">Selling Partner API</b>
        </p>
        <p className="text-para">
          Enter your account name and begin authorization by clicking the button
          below.
        </p>
        <p className="text-para">
          You will be taken to Amazon's authorization page. Once authorized, we
          will begin creating your Visual COGS reports.
        </p>
        <p className="text-para">
          If you have any questions, feel free to email{" "}
          <a href="mailto:CustomerRelations@VisualCOGS.com">
            CustomerRelations@VisualCOGS.com
          </a>
          .
        </p>

        <div className="maxw-500">
          <input
            type="text"
            placeholder="Enter Your Business Name"
            className="form-control form-control-sm"
            value={name}
            onChange={(e) => {
              let myRegEx = /[^a-z\d\s]/i;
              if (!myRegEx.test(e.target.value)) {
                setName(e.target.value);
              }
            }}
          />
          <br />
        </div>

        <button
          className="App-link"
          disabled={!name}
          onClick={() => {
            postCall(getBaseApi() + BackendAPIs.scapi.getSCConsentUrl, {
              name,
            }).then((resp) => {
              if (resp.isValid) {
                window.location.href = resp.url;
              }
            });
          }}
        >
          Authorize
        </button>
      </header>
    </div>
  );
}

export default Home;

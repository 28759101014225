import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import NoMatch from "./Components/NoMatch/NoMatch";
import SCRedirect from "./Components/SCRedirect/SCRedirect";
import VCSCAPI from "./Components/VCSCAPI/VCSCAPI";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/vc-sc-api" element={<VCSCAPI />}></Route>
          <Route
            exact
            path="/vc-sc-api/redirect"
            element={<SCRedirect />}
          ></Route>
          <Route path="*" element={<NoMatch />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

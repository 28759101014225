import jQuery from "jquery";

let httpCalls = [];

export const postCall = (
  url,
  data,
  downloadRequest,
  fileName,
  ignoreException,
  stoploading,
  additionalHeaders
) => {
  if (!stoploading) {
    httpCalls.push(1);
    jQuery("body").addClass("loading");
  }
  let headers = {};
  if (additionalHeaders) {
    headers = { ...additionalHeaders };
  }
  const contentType = "application/json; charset=utf-8";
  const type = "post";
  return jQuery
    .ajax({
      url,
      data: JSON.stringify(data),
      headers,
      contentType,
      type,
      xhr: () => {
        let xhr = new XMLHttpRequest();
        if (downloadRequest) {
          xhr.responseType = "arraybuffer";
        }
        return xhr;
      },
    })
    .then(
      (resp) => {
        if (!stoploading) {
          httpCalls.pop();
          if (!httpCalls.length) {
            jQuery("body").removeClass("loading");
          }
        }
        if (downloadRequest) {
          try {
            var blob = new Blob([resp]);

            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } catch (exc) {
            console.log(
              "Save Blob method failed with the following exception."
            );
            console.log(exc);
          }
        }
        return resp;
      },
      (err) => {
        if (!stoploading) {
          httpCalls.pop();
          if (!httpCalls.length) {
            jQuery("body").removeClass("loading");
          }
        }
        if (!ignoreException) {
          alert("Something went wrong!!!");
        }
        throw err;
      }
    );
};

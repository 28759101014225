import { useEffect, useState } from "react";
import { BackendAPIs, getBaseApi } from "../../constant";
import { postCall } from "../../http-service";
import Loading from "../Loading/Loading";
import "./SCRedirect.css";

function SCRedirect() {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      postCall(
        getBaseApi() + BackendAPIs.scapi.varifyOAuth,
        {
          query: window.location.search,
        },
        false,
        false,
        true
      ).then(
        (resp) => {
          setLoading(false);
          if (!resp.isValid) {
            setError(true);
          }
        },
        () => {
          setLoading(false);
          setError(true);
        }
      );
    });
  }, []);

  return (
    <Loading
      loading={loading}
      success={!error && !loading}
      message={
        loading
          ? ""
          : error
          ? "Something went wrong!!"
          : "Successfully Authorized."
      }
    />
  );
}

export default SCRedirect;

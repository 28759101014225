export const getBaseApi = function () {
  if (window.location.hostname === "localhost") {
    return window.location.origin.split("300")[0] + "5000";
  }
  return "https://api.visualcogs.com";
};

export const BackendAPIs = {
  scapi: {
    getSCConsentUrl: "/scapi/getSCConsentUrl",
    getSCConfirmUrl: "/scapi/getSCConfirmUrl",
    varifyOAuth: "/scapi/varifyOAuth",
  },
};

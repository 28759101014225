import { Spinner } from "react-bootstrap";
import "./Loading.css";

function Loading({ loading, success, message }) {
  return (
    <div className="App">
      <div className="Payment">
        <div className="Payment__Card">
          <div className="Payment__Icon">
            {loading ? (
              <Spinner
                as="span"
                className="spinner"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="success"
              />
            ) : success ? (
              <i className="Payment__Icon__Success">&#10004;</i>
            ) : (
              <i className="Payment__Icon__Failed">&#10006;</i>
            )}
          </div>
          {loading ? (
            <p className="Payment__Card__Message">
              Please Wait! we are processing your request.
              <br />
              Please DO NOT hit the back or refresh button.
            </p>
          ) : null}
          {!loading && success ? (
            <h1 className="Payment__Message__Success">Success</h1>
          ) : null}
          {!loading && !success ? (
            <h1 className="Payment__Message__Failed">FAILED</h1>
          ) : null}
          {message ? <p className="Payment__Card__Message">{message}</p> : null}
          <br />
          {loading ? null : (
            <div className="form__group mt-3">
              <button
                className="btn btn--green"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Go To Home Page &rarr;
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Loading;

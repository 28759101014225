import { useEffect, useState } from "react";
import { BackendAPIs, getBaseApi } from "../../constant";
import { postCall } from "../../http-service";
import Loading from "../Loading/Loading";
import "./VCSCAPI.css";

function VCSCAPI() {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      postCall(
        getBaseApi() + BackendAPIs.scapi.getSCConfirmUrl,
        {
          query: window.location.search,
        },
        false,
        false,
        true
      ).then(
        (resp) => {
          setLoading(false);
          if (resp.isValid) {
            window.location.href = resp.url;
          } else {
            setError(true);
          }
        },
        () => {
          setLoading(false);
          setError(true);
        }
      );
    });
  }, []);

  return (
    <Loading
      loading={loading}
      success={!error && !loading}
      message={
        loading ? "" : error ? "Something went wrong!!" : "Redirecting....."
      }
    />
  );
}

export default VCSCAPI;
